import "./Home.css";

export default function Home() {
    return <section className="Accueil_Front" id="home">
        <div className="reveal accueil_content">
            <div className="accueil_content-container container">
                <h1><span>Let's deliver value together</span></h1>
            </div>
        </div>
    </section>
}