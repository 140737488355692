import './App.css';
import React, { useState } from "react";

import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import Accueil from './pages/Accueil';
import Careers from './pages/Careers';
import Header from './components/Header';
import Footer from './components/Footer';
import Contact from "./components/Contact";

const App = ({ setEtat },{etat}) => {
  let routes = useRoutes([
    { path: "/", element: <Accueil /> },
    { path: "/Careers", element: <Careers setEtat={setEtat} etat={etat}/> },
  ]);
  return routes;
};

const AppWrapper = () => {

  const [etat, setEtat] = useState(false);
  if (etat) {
    return (
      <Contact setEtat={setEtat} etat={etat} />
    );
  }
  return (
    [
    <Router>
      <Header setEtat={setEtat} etat={etat} />
        <App setEtat={setEtat} etat={etat} />
      <Footer />
    </Router>]
  );
};

export default AppWrapper;
