import React, { useEffect } from "react";
import "./About.css";
import { HashLink as Link } from 'react-router-hash-link';

export default function About() {

  useEffect(() => {

  });

  return <section className="reveal" id="services">
    <div className="container" style={{ padding: '50px 10px' }}>
      <div className="services_desc">
        <div className="textwedo"><Link to="/#services">What we do</Link></div>
        <div className="row">
          <div className="col-sm-12 col-md-3 service">
            <div className="service_icon">
              <img alt="service Security" src="./services/service3.jpg" />
            </div>
            <div className="service_text"><h3>Security and Risk Management</h3></div>
          </div>
          <div className="col-sm-12 col-md-3 service">
            <div className="service_icon"><img alt="service Software Engineering" src="./services/service1.jpg" /></div>
            <div className="service_text"><h3>Software Engineering</h3></div>
          </div>
          <div className="col-sm-12 col-md-3 service">
            <div className="service_icon"><img alt="service DevOps" src="./services/service5.jpg" /></div>
            <div className="service_text"><h3>DevOps</h3></div>
          </div>
          <div className="col-sm-12 col-md-12 speace empty">
            <br />
          </div>
          <div className="col-sm-12 col-md-2 empty">
          </div>
          <div className="col-sm-12 col-md-3 service">
            <div className="service_icon"><img alt="service Data Analytics" src="./services/service4.jpg" /></div>
            <div className="service_text"><h3>Data Analytics and AI </h3></div>
          </div>
          <div className="col-sm-12 col-md-3 service">
            <div className="service_icon"><img alt="service Cloud Infrastructure" src="./services/service2.jpg" /></div>
            <div className="service_text"><h3>Cloud Infrastructure</h3></div>
          </div>
          <div className="col-sm-12 col-md-2 empty">
          </div>
        </div>
      </div>
    </div>
  </section>
}