import "./Services.css";
import React, { useEffect } from "react";
import { HashLink as Link } from 'react-router-hash-link';

export default function Services() {
    useEffect(() => {

    });
    return <section className="reveal" id="about">
        <div className="container">
            <div className="about_div">
                <div className="textwedo"><Link to="/#about">Who we are</Link></div>
                <div className="descriptwedo"><p>Valorem means 'Value' in latin</p></div>
                <div className="descriptwedo"><p className="">Our aim at Valorem technologies is to help companies solve complex business challenges and create valuable assets</p></div>
            </div>
        </div>
    </section>
}