import React, { useEffect } from "react";
import $ from "jquery";
import './Careers.css';
import { Helmet } from "react-helmet";

export default function Careers({ setEtat }) {
    useEffect(() => {
        if ($(".header").css('background-color') !== 'black') {
            $(".header").css({ "background-color": "black" });
            $(".btn_navbar").addClass("btn_navbar_action");}
        let menu = document.querySelectorAll(".navbar .nav-link");
        if (window.location.href.includes("Careers")) {
            menu.forEach((link) => {
                $(link).removeClass("active");
            });
            $(".nav-link[href*=Careers]").addClass("active");
        }
    });

    return <main>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Valorem technologies | Career</title>
            <meta name="description" content="" />
        </Helmet>
        <section className="container" id="Careers">
            <div className="head">
                <p className="icon_conta">There is no job openings  currently !</p>
                <p className="icon_conta">we are always looking for new talent, so please use this from in order to submit a spontaneous job application<a className="linktocontact" onClick={() => setEtat(true)}>Here.</a></p>
            </div>
        </section>
    </main>
}