import { HashLink as Link } from 'react-router-hash-link';
import './Footer.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'


export default function Footer() {

  return <footer className="">
    <div className='container border-bottom'>
      <div className='row'>
        <div className='col-sm-12 col-md-3 txt_centre'>
          <ul className=" justify-content-center link_footer">
            <li className="nav-item"><Link className="nav-link px-2 text-muted" aria-current="page" to="/#home"> Home</Link></li>
            <li className="nav-item"><Link className="nav-link px-2 text-muted" to="/#about"> Who we are</Link></li>
            <li className="nav-item"><Link className="nav-link px-2 text-muted" to="/#services"> What we do</Link></li>
            <li className="nav-item"><Link className="nav-link px-2 text-muted" to='/Careers'> Careers</Link></li>
          </ul>
        </div>
        <div className='col-sm-12 col-md-3'>
          <ul className="justify-content-end link_footer floatr">
            <li className="nav-item link_infos"><a className="white_svg" rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/valorem-technologies/mycompany/"><FontAwesomeIcon icon={faLinkedin} size="lg" /></a></li>
            <li className="nav-item link_infos"><FontAwesomeIcon icon={faEnvelope} size="lg" />   contact@valoremtech.ca</li>
            <li className="nav-item link_infos"><FontAwesomeIcon icon={faPhone} size="lg" />   +1 514-431-3969</li>
          </ul>
        </div>
      </div>


    </div>
    <div className="d-flex flex-wrap justify-content-between align-items-center container info_footer">
      <div className="col-md-4 d-flex align-items-center">
        <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
          <img className="icon_footer" alt='icon logo footer' src="./valorem.png" />
        </a>
        <span className="mb-3 mb-md-0 text-muted">© 2022 VALOREM TECHNOLOGIES INC</span>
      </div>
    </div>
  </footer>
}