import "./MenuNav.css";
import React, { useEffect } from "react";
import $ from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
import { HashLink as Link } from 'react-router-hash-link';

export default function MenuNav({ setEtat }) {

  useEffect(() => {
    var last = 999999;
    $(window).resize(function () {
      if ($(window).width() >= 990) {
        if ($(".collapse.navbar-collapse").hasClass("show")) {
          $(".collapse.navbar-collapse").removeClass("show")
        }
      }
    });

    $(window).scroll(function () {
      var height = $(window).scrollTop();
      if (height > 0) {
        if ($(".header").css('background-color') !== 'black') {
          $(".header").css({ "background-color": "black" });
          $(".btn_navbar").addClass("btn_navbar_action");
        }
        $(".navbar-toggler").addClass("navbar-toggler-active");
        //$("#navbar").addClass("navscroll");
        if (height > last) {
          if (!$('#navbar').hasClass("hide_navbar")) {
            $('#navbar').addClass("hide_navbar");
            $(".navbar-collapse.collapse.show").removeClass('show');
          }
        } else {
          if ($('#navbar').hasClass("hide_navbar")) {
            $("#navbar").removeClass("hide_navbar");
          }
        }
        if (height > (last + 100) || last > height + 100 || height > $("body").height()) {
          last = height;
        }
      }
      else {
        last = height;
        $("#navbar").removeClass("hide_navbar");
        $(".btn_navbar").removeClass("btn_navbar_action");
        if (!window.location.href.includes("Careers")) {
          $(".navbar-toggler").removeClass("navbar-toggler-active");
          $(".header").css({ "background-color": "transparent" });
      }
        //$("#navbar").removeClass("navscroll");
      }

    });
  });

  return <nav id="navbar" className="navbar navbar-expand-lg navbar-light">
    <a className="navbar-brand" href="/"><img className="icon_home" alt="logo valorem" src="./valorem.png" /></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <FontAwesomeIcon icon={faBarsStaggered} size="lg" />
    </button>
    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div className="float-end flex">
        <div className="navbar-nav">
          <Link className="nav-link color-white active" aria-current="page" to="/#home"> Home</Link>
          <Link className="nav-link color-white" to="/#about"> Who we are</Link>
          <Link className="nav-link color-white" to="/#services"> What we do</Link>
          <Link className="nav-link color-white" to='/Careers'> Careers</Link>
          <a className="nav-link btn_navbar" onClick={() => setEtat(true)}><p>Let's Talk</p></a>
        </div>
      </div>
    </div>
  </nav>
}