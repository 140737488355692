import React from "react"
import './Header.css';
import MenuNav from "./MenuNav";


function talkus(etat, setEtat) {
  if (etat) {
    return "";
  }
  return <header className="header">
    <div className="container">
      <MenuNav setEtat={setEtat} />
    </div>
  </header>;
}

export default function Header({ setEtat }, { etat }) {
  return talkus(etat, setEtat);
}