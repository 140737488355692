import './Contact.css';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Helmet } from "react-helmet";
import { useEffect, useRef, useState } from "react";
import $ from 'jquery';

export default function Contact({ setEtat }) {
    const [token, setToken] = useState(null);
    const [form, setForm] = useState({
        fields: {
            email: "",
            name: "",
            message: "",
            captcha: "",
        },
        errors: {
            text_email: "",
            text_name: "",
            text_message: "",
            text_captcha: "",
        },
    });

    const onUpdateField = e => {
        let fields = form.fields;
        fields[e.target.name] = e.target.value;
        setForm({ fields: fields, errors: form.errors });
    };

    const onSubmitForm = e => {
        let fields = form.fields;
        let error = form.errors;
        e.preventDefault();

        if (handleValidation()) {
            $.ajax({
                type: "POST",
                url: "/contact.php",
                data: JSON.stringify(fields),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (data) {alert("success"); setEtat(false); },
                error: function (errMsg) {
                    alert("error");
                    setForm({ fields: fields, errors: error });
                }
            });
            /*
            $.post("contact", JSON.stringify(fields));
            setEtat(false);*/
        } else {
            setForm({ fields: fields, errors: error });
        }
    };

    const captchaRef = useRef(null);

    const onLoad = () => {
        captchaRef.current.execute();
    };

    useEffect(() => {

        if (token) {
            let fields = form.fields;
            fields["captcha"] = token;
            setForm({ fields: fields, errors: form.errors });
        }

    }, [token]);

    useEffect(() => {

    }, [form.errors]);

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    function handleValidation() {
        let fields = form.fields;
        let errors = form.errors;
        let formIsValid = true;

        //Name
        if (fields["name"] === "") {
            formIsValid = false;
            errors["text_name"] = "Cannot be empty";
        } else
            if (fields["name"] !== "") {
                if (!fields["name"].match(/^[a-zA-Z ]+$/)) {
                    formIsValid = false;
                    errors["text_name"] = "Only letters";
                } else {
                    errors["text_name"] = "";
                }
            }

        //Email
        if (fields["email"] === "") {
            formIsValid = false;
            errors["text_email"] = "Cannot be empty";
        } else
            if (fields["email"] !== "") {
                if (!validateEmail(fields["email"])) {
                    formIsValid = false;
                    errors["text_email"] = "Email is not valid";
                } else {
                    errors["text_email"] = "";
                }

            }
        //Message
        if (fields["message"].trim() === "") {
            formIsValid = false;
            errors["text_message"] = "Cannot be empty";
        } else {
            errors["text_message"] = "";
        }

        //captcha
        if (fields["captcha"].trim() === "") {
            formIsValid = false;
            errors["text_captcha"] = "You need to do the captcha";
        } else {
            errors["text_captcha"] = "";
        }
        setForm({ fields: form.fields, errors: errors });
        return formIsValid;
    }

    return <section className="talk" id="contact">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Valorem technologies | Contact</title>
            <meta name="description" content="" />
        </Helmet>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="row icon_close">
                        <div className="col-6"><a className="navbar-brand" href="/"><p className="icon_conta">Valorem technologies</p></a></div>
                        <div className="col-6"><div className="div_btn_close"><button type="button" className="btn_close" onClick={() => setEtat(false)}><p>X</p></button></div></div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-12 col-lg-6 text_conta"><p className="text_conta_first">Let's talk about your next big project.</p></div>
                        <div className="col-md-12 col-lg-6">
                            <form type="POST" name="contactform"
                                className="contactform"
                                onSubmit={onSubmitForm}>
                                <div className="form_content">
                                    <div className="form_txt">
                                        <div><p>Name*:</p></div>
                                        <div><input name='name' className="input_form" value={form.fields.name}
                                            onChange={onUpdateField} type="text" placeholder="Full Name" /></div>
                                        <div className="text_error"><p>{form.errors["text_name"]}</p></div>
                                    </div>
                                    <div className="form_txt">
                                        <div><p>Email*:</p></div>
                                        <div><input name='email' className="input_form" value={form.fields.email}
                                            onChange={onUpdateField} type="Email" placeholder="name@example.com" /></div>
                                        <div className="text_error"><p>{form.errors["text_email"]}</p></div>
                                    </div>
                                    <div className="form_txt">
                                        <div><p>Message:</p></div>
                                        <div><textarea name='message' className="input_form" value={form.fields.message}
                                            onChange={onUpdateField} placeholder="Add details about your project..." />
                                            <div className="text_error"><p>{form.errors["text_message"]}</p></div>
                                        </div>
                                    </div>
                                    <div className="form_txt">
                                        <HCaptcha onLoad={onLoad}
                                            onVerify={setToken}
                                            ref={captchaRef}
                                            sitekey="8340790a-67df-4e42-9905-47e9c9a8e007"

                                        />
                                        <div className="text_error"><p>{form.errors["text_captcha"]}</p></div>
                                    </div>
                                    <div className="form_txt"><button type="submit" className="btn btn-success">Submit</button></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}
