import React,{useEffect} from "react"
import './Accueil.css';
import $ from "jquery";
import { Helmet } from "react-helmet";
import Home from '../parts/Accueil/Home';
import Services from '../parts/Accueil/Services';
import About from '../parts/Accueil/About';

export default function Accueil() {

    useEffect(() => {
        reveal();
        let section = document.querySelectorAll("section");
        let menu = document.querySelectorAll(".navbar .nav-link")
    
        function reveal() {
          var reveals = document.querySelectorAll(".reveal");
    
          for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;
    
            if (elementTop < windowHeight - elementVisible) {
              $(".reveal").eq(i).addClass("active");
            } else {
              $(".reveal").eq(i).removeClass("active");
            }
          }
        }
        var dest = 0;
        $(window).scroll(function () {
          if ($(window).scrollTop() - dest > 100 || dest - $(window).scrollTop() > 100) {
            dest = $(window).scrollTop();
            reveal();
            section.forEach((i) => {
              let top = window.scrollY;
              let offset = i.offsetTop - 150;
              let height = i.offsetHeight;
              let id = i.getAttribute("id");
    
              if (top >= offset && top < offset + height) {
                menu.forEach((link) => {
                  $(link).removeClass("active");
                });
                $(".nav-link[href*=" + id + "]").addClass("active");
              }
            });
          }
        });
      });

    return <main>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Valorem technologies</title>
            <meta name="description" content="Valorem technologies" />
        </Helmet>
        
        <Home />
        <About />
        <Services />
    </main>
}